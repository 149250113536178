import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    CanLoad,
    ActivatedRouteSnapshot,
    Route,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { OAuthService } from './oauth.service';
import { SettingService } from './setting.service';

@Injectable({
    providedIn: 'root'
})
export class ACLGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        public authenticationService: OAuthService,
        public userService: UserService,
        public settingService: SettingService,

        public _router: Router
    ) { }
    canLoad(route: Route): Observable<boolean> {
        return this.process();
    }
    // all children route
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> {
        return this.canActivate(childRoute, state);
    }
    // route
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> {
        return this.process();
    }

    public process(): Observable<boolean> {
        this.authenticationService.checkCredentials();
        var guard$: Observable<boolean>;
        guard$ = new Observable((observer) => {
            if (!this.settingService.getUser()) {
                this.userService.getUserPrincipal().subscribe(prinicipal => {
                    if (prinicipal == undefined){
                        this._router.navigate(['/login']);
                        observer.next(false);
                        observer.complete();
                        return false;
                    }
                    observer.next(true);
                    observer.complete();
                });
            } else {
                // observable execution
                observer.next(true);
                observer.complete();
            }
        });

        return guard$;
    }


}