import { Authority } from "./authority";

export class User {

    id?: number;
    userName?: string;
    pwdModifyDate?: Date;
    lastLoginDate?: Date;
    enabled?: boolean;
    displayName?: string;
    pwd?: string;
    createDate?: Date;
    createUser?: string;
    updateDate?: Date;
    updateUser?: string;
    address?: string;
    phoneNo?: string;
    email?: string;
    driverId?: number;
    authorities?:string[];
    authoritiesDetailList?: Authority[];
}
