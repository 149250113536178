import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cookie } from 'ng2-cookies';
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<HttpEventType.Response>> {
    let authReq = req;
    if (Cookie.get('access_token')){
      authReq = req.clone({
        setHeaders: { 'Authorization': 'Bearer '+Cookie.get('access_token') }
      });
    }
    return next.handle(authReq);
  }

}