import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../entity/user';
import { OAuthService } from '../../services/oauth.service';
import { FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  public loginData = { username: "", password: "" };

  loading = false;
  error = '';

  constructor(
    public router: Router,
    public authenticationService: OAuthService,
    public fb: FormBuilder
  ) {
    document.body.style.backgroundColor = "#3f51b5";

  }
  loginForm = this.fb.group({
    username: [''],
    password: ['']

  }
  );

  login() {
    this.loading = true;
    this.loginData.username = this.loginForm.value.username;
    this.loginData.password = this.loginForm.value.password;
    this.authenticationService.obtainAccessToken(this.loginData).subscribe(
      data => {
        this.authenticationService.saveToken(data);
        this.loading = false;
      },
      err => {
        alert('Invalid Credentials');
        this.loading = false;
      }
    );
  }

}
