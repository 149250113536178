import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UserPrincipal } from '../entity/user-principal';
import { User } from '../entity/user';
import {Router} from '@angular/router';
import { Authority } from '../entity/authority';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: User;
  constructor(
    public http: HttpClient,
    public _router: Router
  ) {
    console.log("constructor loaded")
   }

  public getUser():User{
    return this.user;
  }
  public setUser(user:User):void{
    user = this.user;
  }

  getUserPrincipal(): Observable<UserPrincipal> {
    const url = `/uaa/user/`;
    return this.http.get<UserPrincipal>(url).pipe(
      tap(_ => this.log(`fetched getUserPrincipal`)),
      catchError(this.handleErrorWithoutRedirect<UserPrincipal>(`getUserPrincipal`))
    );
  }
  getUserDetail(filterUser:User): Observable<User> {
    const url = `/accountService/getUserInfo`;
    return this.http.post<User>(url,filterUser).pipe(
      tap(user => {
        this.log(`fetched getUserDetail`)
        this.user = user
      }),
      catchError(this.handleError<User>(`getUserDetail`))
    );
  }

  
  saveUser(user: User): Observable<User> {
    const url = `/accountService/user/save`;
    return this.http.post<User>(url, user).pipe(
      tap(_ => {
        this.log(`saveUser id=${_.id}`);
         }),
      catchError(this.handleError<User>(`saveMonthlyRecordDetail id=${user.id}`))
    );
  }
  getAuthorityOptionList(): Observable<Authority[]> {
    return this.http.get<Authority[]>('/accountService/getAuthorityList',httpOptions)
      .pipe(
        tap(authorityOptionList => this.log('fetched getAuthorityList')),
        catchError(this.handleError('getAuthorityList', []))
      );
  }
  
  getUserListByFilter (filter: User): Observable<User[]> {
    return this.http.post<User[]>('/accountService/user/list',{filter}, httpOptions)
      .pipe(
        tap(userList => this.log('fetched getUserListByFilter')),
        catchError(this.handleError('getUserListByFilter', []))
      );
  }
   /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
 
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      if (error.status == 401){
        this._router.navigate(['/login']);
      }
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  public handleErrorWithoutRedirect<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
 
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      //this.toastr.pop('error','', `${operation} failed: ${error.message}`);
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  /** Log a HeroService message with the MessageService */
  public log(message: string) {
   console.log(`UserService: ${message}`);
  }
}