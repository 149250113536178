import { Injectable, Injector, Inject } from '@angular/core';
import { User } from '../entity/user';


@Injectable({
    providedIn: 'root'
})
export class SettingService {
    public user: User;

    public getUser(): User {
        return this.user;
    }
    public setUser(user: User): void {
        this.user= user;
    }
}