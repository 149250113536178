import { Injectable, Injector, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SettingService } from './setting.service';
import { OAuthService } from './oauth.service';
import { UserService } from './user.service';
import { User } from '../entity/user';


@Injectable({
    providedIn: 'root'
  })
export class StartupService {
  constructor(
    public settingService: SettingService,
    public authenticationService: OAuthService,
    public userService: UserService,
    public httpClient: HttpClient,
    public injector: Injector,
  ) {}

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
        this.userService.getUserPrincipal().subscribe(prinicipal => {
            if (!prinicipal){
                resolve(null);
            }
            else {
                var user = new User();
                user.userName = prinicipal.name;
                this.userService.getUserDetail(user).subscribe(user => {
                    this.settingService.setUser(user);
                    console.log('resolve')
                    resolve(null);
    
    
                });
            }
            
        });

        
    });
  }
}