import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingService } from '../services/setting.service';
import { OAuthService } from '../services/oauth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnDestroy {


    mobileQuery: MediaQueryList;

    public settingService: SettingService

    public _mobileQueryListener: () => void;
  
    constructor(public authenticationService: OAuthService,
      changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, settingService: SettingService) {
      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
      this.settingService = settingService;
    }
  
    ngOnDestroy(): void {
      this.mobileQuery.removeListener(this._mobileQueryListener);
    }
    logout(){
      this.authenticationService.logout();
    }
}
