import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { Cookie } from 'ng2-cookies';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { User } from '../entity/user';
import { SettingService } from './setting.service';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';

export class Foo {
  constructor(
    public id: number,
    public name: string) { }
} 

@Injectable({
    providedIn: 'root'
  })
export class OAuthService {
  constructor(
    public _router: Router, public _http: HttpClient,
    public userService: UserService,
    public settingService: SettingService
  ){}
 
  obtainAccessToken(loginData):Observable<any>{
    Cookie.delete('access_token');
    let params = new URLSearchParams();
    params.append('username',loginData.username);
    params.append('password',loginData.password);    
    params.append('grant_type','password');
    params.append('client_id','fooClientIdPassword');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded',
        'Authorization': 'Basic '+btoa("fooClientIdPassword:secret")
        
      }
      ),
      withCredentials: true

    };
    console.log(params.toString());
    return this._http.post('/uaa/oauth/token', params.toString(), httpOptions).pipe(res=>res);
    
  }


  saveToken(token){
    var expireDate = new Date().getTime() + (1000 * token.expires_in);
    Cookie.set("access_token", token.access_token, expireDate);
    console.log('Obtained Access token');
    this.userService.getUserPrincipal().subscribe(prinicipal => {
      if (prinicipal){
      
        var user = new User();
        user.userName = prinicipal.name;
        this.userService.getUserDetail(user).subscribe(user => {
          this.settingService.setUser(user);
          this._router.navigate(['/']);


        });
     }
  });
  }

  getResource(resourceUrl) : Observable<Foo>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded; charset=utf-8',
        'Authorization': 'Bearer '+Cookie.get('access_token')
      })
    };
    return this._http.get<Foo>(resourceUrl, httpOptions).pipe(catchError((error:any) => Observable.throw(error.json().error || 'Server error')));
  }

  checkCredentials(){
    if (!Cookie.check('access_token')){
        this._router.navigate(['/login']);
    }
  } 

  logout() {
    Cookie.delete('access_token');
    this._router.navigate(['/login']);
  }
}